import {h} from 'preact';
import {Link} from 'preact-router';
import {useState} from 'preact/hooks';
import {User} from 'preact-feather';
import IdentityModal, { useIdentityContext } from "react-netlify-identity-widget"
import "react-netlify-identity-widget/styles.css"
import style from './style.css';

{/* <Auth /> */}
const Auth = () => {
	const {isLoggedIn} = useIdentityContext();
	const [dialog, setDialog] = useState(false)

	return (
		<Link 
			id="add-button" 
			class={isLoggedIn ? 'primary is-fixed is-text-center button': 'is-fixed is-text-center button'} 
			onClick={() => {setDialog(true)}}
		>
			<User />
			<IdentityModal showDialog={dialog} onCloseDialog={() => setDialog(false)} />
		</Link>
	)
}

export default Auth;