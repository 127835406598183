import {Provider} from 'unistore/preact';
// import netlifyIdentity from 'netlify-identity-widget';

// import { IdentityContextProvider } from "react-netlify-identity"
import { IdentityContextProvider } from "react-netlify-identity-widget"


import '../node_modules/chota/dist/chota.css';
import './style';
import store from './store';


import App from './components/app';


// window.netlifyIdentity = netlifyIdentity;
// You must run this once before trying to interact with the widget
// netlifyIdentity.init();


const url = "https://repartidor.iglesianorte.es/" // supply the url of your Netlify site instance with Identity enabled. VERY IMPORTANT

const ProviderApp = () => (

    <Provider store={store}>
        <IdentityContextProvider url={url}>
        {/* <IdentityContextProvider url={url}> */}
            <App />
        {/* </IdentityContextProvider> */}
        </IdentityContextProvider>
    </Provider>
)

export default ProviderApp;
