import {h, Component} from 'preact';
import linkstate from 'linkstate';
import {Link, getCurrentUrl, route} from 'preact-router';
import {Edit2, Phone, Clock, Trash2} from 'preact-feather';
import {connect} from 'unistore/preact';

import actions from '../../actions';


class OrderDetails extends Component {
    state = {
        currentOrder: null,
        paid: null,
        orderStatus: null
    }

    setOrder(props) {
        props = props ? props : this.props;

        let {orders, orderId} = props;
        let order = orders.filter(o => o.id == orderId)[0];

        if(order) {
            this.setState({
                currentOrder: order,
                paid: order.fields.paid || null,
                orderStatus: order.fields.status || null
            });
        }
    }
    
    delete = (e) => {
        e.preventDefault();

		if (confirm("¿Quieres eliminar este pedido? No habrá vuelta atras ;)") == true) {
            this.props.deleteOrder(this.state.currentOrder.id, '/');
		}
    }

    prepareUpdates() {
        return {
            fields: {
                status: this.state.orderStatus !== "" ? this.state.orderStatus : null,
                paid: this.state.paid ? parseInt(this.state.paid) : null
            }
        }
    }

    updateStates = () => {
        // console.log(this.state.currentOrder.id);
        let data = this.prepareUpdates();

        this.props.updateOrder(this.state.currentOrder.id, data, '/');
    }

    componentWillReceiveProps(props) {
        this.setOrder(props);
	}
    
    componentWillMount() {
        this.setOrder();
    }
    
    render({}, {currentOrder, paid, orderStatus}) {
        if(!currentOrder) {
            return(<div class="wrapper info">Cargando...</div>)
        }

        let addr = currentOrder.fields.address ? currentOrder.fields.address.split(',')[0] : '';
        let apartment = currentOrder.fields.apartment || '';
        
        return (
            <div class="wrapper info">
                <h1>
                    Pedido 
                    {/* <span class="pull-right">
                        <Link class="button icon-only" href={getCurrentUrl() + '/edit'}><Edit2 /></Link>
                        <button class="button error icon-only" onClick={this.delete}><Trash2 /></button>
                    </span> */}
                </h1>

                <div>
                    <h6>Info</h6>
                    <p>
                        { currentOrder.fields.name }<br/>
                        { currentOrder.fields.phone && (
                            <div>
                                <Phone class="icon-inline" /> <Link href={'tel://'+parseInt(currentOrder.fields.phone)}>{parseInt(currentOrder.fields.phone)}</Link>
                            </div>
                        )}
                        { currentOrder.fields.time && (<div><Clock class="icon-inline" /> {currentOrder.fields.time}</div>) }
                    </p>
                    <br/>
                    <p>
                        {addr && addr}{apartment && (<span> - {apartment}<br/></span>)}
                        {/* {currentOrder.fields.address}<br/>
                        {currentOrder.fields.apartment}<br/> */}
                    </p>
                </div>
                <br/>
                <div>
                    <h6>Pedido</h6>
                    <ul>
                        {currentOrder.fields.papas > 0 &&  
                            <li>
                                {currentOrder.fields.papas} papas 
                                <span style="float:right;">{currentOrder.fields.papas * 3}€</span>
                            </li>
                        }
                        {currentOrder.fields.empanadas > 0 && 
                            <li>
                                {currentOrder.fields.empanadas} empanadas
                                <span style="float:right;">{currentOrder.fields.empanadas * 1}€</span>
                            </li>
                        }
                        {currentOrder.fields.tamales > 0 && 
                            <li>
                                {currentOrder.fields.tamales} tamales
                                <span style="float:right;">{currentOrder.fields.tamales * 6}€</span>
                            </li>
                        }
                        <hr/>
                        <li>
                            <strong>Precio total</strong>
                            <strong style="float:right;">{currentOrder.fields.price}€</strong>
                        </li>
                    </ul>
                </div>

                <div>
                    <label for="orderState">Estado del pedido</label>
                    <select class="large" id="orderState" name="orderState" value={orderStatus} onChange={linkstate(this, 'orderStatus')}>
                         <option value="ready">Preparado</option>
                         <option value="delivery">En reparto</option>
                         <option value="done">Listo</option>
                     </select>
                     {/* <div class="button-group">
                        <button class="button">Preparado</button>
                        <button class="button">En reparto</button>
                        <button class="button">Listo</button>
                     </div>  */}
                </div>
                <div>
                    <label for="paidStatus">Pagado (€)</label>
                    <input id="paidStatus" name="paidStatus" type="number" placeholder="0" value={paid} onInput={linkstate(this, 'paid')} />
                </div>
                
                <footer id="footer-form" class="is-vertical-align is-horizontal-align" >
                    {/* <Link class="button error" href="" onClick={this.delete}>Eliminar</Link> */}
                    <Link class="button" href="/">Cancelar</Link>
                    <button class="button primary" onClick={this.updateStates}>Guardar</button>
                </footer>
            </div>
        )
    }
}

export default connect("orders", actions)(OrderDetails);