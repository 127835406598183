import createStore from 'unistore'
// import devtools    from 'unistore/devtools'


let initialState = { 
    orders: [],
    currentOrder: null,
    message: {},
    showMessage: false,
    isLoading: false,
    messages: {
        addOrder: {
            success: {content: "El pedido se ha guardado correctamente.", type: "success"},
            error: {content: "Ha habido un error.", type: "error"}
        },
        updateOrder: {
            success: {content: "El pedido se ha actualizado correctamente.", type: "success"},
            error: {content: "Ha habido un error.", type: "error"}
        },
        deleteOrder: {
            success: {content: "El pedido se ha eliminado correctamente.", type: "success"},
            error: {content: "No se pudo eliminar el pedido.", type: "error"}
        }
    }
};

let store = createStore(initialState);

export default store;

// let store = process.env.NODE_ENV === 'production' ?  createStore(initialState) : devtools(createStore(initialState));
