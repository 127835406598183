import {route} from 'preact-router';
import { useIdentityContext } from "react-netlify-identity-widget";

// let apiUrl = 'https://api.airtable.com/v0/appx93j9ndOyRUn8a/06jul19/';
// let apiUrl = 'https://api.airtable.com/v0/appx93j9ndOyRUn8a/_test/';
let apiUrl = 'https://api.airtable.com/v0/appx93j9ndOyRUn8a/19oct19/';
// let apiUrl = 'https://api.airtable.com/v0/appx93j9ndOyRUn8a/24ago19/';
let userApiUrl = 'https://api.airtable.com/v0/appx93j9ndOyRUn8a/repartidores/';

let headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer keyLPivNpD2pcXGS4'
};


const actions = (store) => ({
    addOrder(state, data, redirect) {
        this.showLoading(),
        fetch(apiUrl, { method: 'POST', headers: headers, body: JSON.stringify(data) })
                .then(r => r.json())
                .then(res => {
                    let updatedOrders = state.orders.map(o => o);

                    updatedOrders.push(res);
                    store.setState({
                        orders: updatedOrders,
                        message: state.messages.addOrder.success,
                        showMessage: true,
                        isLoading: false
                    })
                    this.hideFeedback();
                    route(redirect);
                })
                .catch(err => this.onError(err, state.messages.addOrder.error));
    },


    updateOrder(state, orderId, data, redirect) {
        this.showLoading(),
        fetch(apiUrl + orderId, { method: 'PATCH', headers: headers, body: JSON.stringify(data)})
                .then(r => r.json())
                .then(res => {
                    let updatedOrders = state.orders.map(order => {
                        if(order.id === res.id) {
                            order = res;
                        }
                        return order;
                    });

                    store.setState({
                        orders: updatedOrders,
                        message: state.messages.updateOrder.success,
                        showMessage: true,
                        isLoading: false
                    })
                    this.hideFeedback();
                    route(redirect);
                })
                .catch(err => this.onError(err, state.messages.updateOrder.error));
    },


    deleteOrder(state, orderId, redirect) {
        this.showLoading(),
        fetch(apiUrl + orderId, { method: 'DELETE', headers: headers })
                .then(r => r.json())
                .then(res => {
                    if(res.deleted) {
                        let orders = state.orders.filter(o => o.id !== res.id);
                        store.setState({
                            orders: orders,
                            message: state.messages.deleteOrder.success,
                            showMessage: true,
                            isLoading: false
                        })
                        this.hideFeedback();
                        route(redirect)
                    }
                })
                .catch(err => this.onError(err, state.messages.deleteOrder.error));
    },


    getOrders() {
        fetch(apiUrl, { method: 'GET', headers: headers })
            .then(r => r.json())
            .then(res => {
                console.log('RESPONSE', res);
                store.setState({orders: res.records})
            })
            .catch(err => this.onError(err));
    },

    onError(err, message) {
        console.log(err);
        store.setState({
            message: message,
            showMessage: true,
            isLoading: false
        });
        this.hideFeedback();
    },

    hideFeedback() {
		setTimeout(() => {
            store.setState({
                showMessage: false,
                message: null
            })
		}, 3000)
    },

    showLoading() {
        store.setState({isLoading: true});
    },

    // setCurrentUser(users) {
    //     return users.filter(u => u.fields.email === 'info@hectorromo.com')[0];
    // },
    
    getUsers() {
        let {isLoggedIn, user} = useIdentityContext();
        fetch(userApiUrl, { method: 'GET', headers: headers })
            .then(r => r.json())
            .then(res => {
                
                // Check against logged in status
                let current = res.records.filter(u => u.fields.email === user.email)[0];
                
                console.log('Action: ', current);

                store.setState({currentUser: current})
            })
            .catch(err => this.onError(err));
    },

});

export default actions;