import { h, Component } from 'preact';
import { Router, route } from 'preact-router';
import {useRef, useState} from 'preact/hooks';
import {connect} from 'unistore/preact';

// import netlifyIdentity from 'netlify-identity-widget';
import { useIdentityContext } from "react-netlify-identity-widget";
// import useLoading from './useLoading';

import actions from '../actions';

// Code-splitting is automated for routes
import Orders from '../routes/orders';
import OrderDetails from '../routes/orders/details';
import MapComponent from '../routes/map';

import Auth from '../components/auth';

// import EditOrder from '../routes/orders/edit';
// import AddOrder from '../routes/orders/add';
// import Redirect from './redirect';

class App extends Component {
	
	state = {
		orders: []
	}

	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url;
	};
	
	componentWillMount = () => {
		// console.log('asdfljsdfk');
		this.props.getOrders();
		this.props.getUsers();
	}

	componentWillReceiveProps() {
		// console.log('asdfljsdfk');
		const {isLoggedIn} = useIdentityContext();
		if (isLoggedIn) {
			// console.log('IS LOGGE IDND');
			this.props.getOrders();
			this.props.getUsers();
		}
	}

	render({message, showMessage, isLoading}, {orders}) {
		const {isLoggedIn} = useIdentityContext();

		let position = {
			lat: 49.8419,
			lng: 24.0315
		}

		return (
			<div id="app" class={isLoggedIn ? '' : 'offline'}>
				{/* <div class="loading-overlay"><span>Cargando...</span></div> */}
				{isLoading && <div class="loading-overlay"><span>Cargando...</span></div>}
				{showMessage && (
					<div id="notification" class={"card text-white bg-"+message.type}>
						<p>{message.content}</p>
					</div>
				)}

				<Auth />

				{
					isLoggedIn &&
					<Router onChange={this.handleRoute}>
						<Orders path="/" />
						<OrderDetails path="/order/:orderId" orders={orders} />
						{/* <EditOrder path="/order/:orderId/edit" orders={orders} /> */}
						{/* <AddOrder path="/order/add" orders={orders} /> */}
						<MapComponent path="/map/" orders={orders} markerPosition={position} />

						{/* <Login path="/login"/> */}

					</Router>
				}
			</div>
		);
	}
}



// eslint-disable-next-line
// function Login({}) {
// 	const {
// 		loginUser,
// 		signupUser,
// 		settings,
// 		loginProvider,
// 		isLoggedIn
// 	} = useIdentityContext();

// 	if(isLoggedIn) {
// 		route('/');
// 	}

// 	return (
// 		<div>You have to login</div>
// 	);
//   }





export default connect("showMessage, message, isLoading", actions)(App)